.container {
  background: rgb(233, 240, 233);
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.card {
  width: 300px;
  height: auto;
  background: rgb(255, 255, 255);
  padding: 1rem;
}

.email-field {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
  padding: 1rem;
}

h1 {
  margin: 0;
  padding: 0;
}
