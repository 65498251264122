.textCenter {
  text-align: center;
}

.item-icon-like {
  bottom: 10px;
  right: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
}

.item-score {
  color: rgb(65, 187, 65);
  height: 35px;
  width: 35px;
  background: rgba(64, 233, 87, 0.137);
  border-radius: 50%;
  cursor: pointer;
}

.item-icon-like:hover .icon-outline,
.item-icon-like .icon-solid {
  display: none;
}
.item-icon-like:hover .icon-solid {
  display: inline;
  color: rgb(223, 0, 0);
  cursor: pointer;
}

.item-logo {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-size: 100% 100%;
  border: 3px solid white;
}

.banner {
  max-height: 600x;
  height: 600px;
  background: #3a6186; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #89253e,
    #3a6186
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
      to right,
      hsla(345, 57%, 34%, 0.76),
      hsla(209, 40%, 38%, 0.76)
    ),
    url(../bannerz.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.banner .banner-buttons {
  color: white;
  padding: 16px;
  position: absolute;
  top: 20vh;
  left: 10%;
}

.banner img {
  max-height: 55vh;
}

.container-about {
  background: #4ecdc4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #556270,
    #4ecdc4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
      to right,
      hsla(211, 14%, 39%, 0.753),
      hsla(176, 56%, 55%, 0.76)
    ),
    url(../bannerz.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  color: white;
  padding: 3rem;
}

.container-about p {
  font-size: 20px;
}

.parallax {
  /* The image used */
  background-image: url('../bannerw.png');

  /* Set a specific height */
  height: 400px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flow {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flow-item {
  height: 300px;
  width: 20%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.flow-item .icon {
  font-size: 5rem;
}

.flow-item h4 {
  text-align: center;
  margin-top: 2rem;
  width: 100%;
}

.payment-methods img {
  margin: 2rem;
}

@media (max-width: 767px) {
  .mb-5 {
    margin-bottom: 5px !important;
  }

  .banner .banner-buttons {
    padding: 5px;
    top: 18vh;
    left: 0;
    text-align: center;
  }

  .flow-item {
    width: 100%;
    margin-top: 15px;
  }
  .container-about {
    padding: 0.5rem;
  }
  .container-about p {
    font-size: 1.2rem;
  }
}
