.main-img-detail {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
}

.mini-img-detail {
  margin-top: 1px;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
}

.mini-img-active {
  border-color: rgb(49, 214, 255);
}

.hide {
  display: none;
}

.show {
  animation-name: slideDown;
  animation-duration: 0.7s;
}

ul {
  list-style-type: none;
}

.name-attribute {
  font-size: 16px;
  font-weight: bold;
}

.label-option {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.label-option:hover {
  color: #f50057;
}

/* For modern browsers */
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}
/* The standard way */
@keyframes slideDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
