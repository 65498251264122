body {
  color: #3a3a3a !important;
  font-family: 'Open Sans', sans-serif !important;
}

.margin-top-responsive {
  margin-top: 100px;
}

$theme-colors: (
  'primary': #348c84,
);

// .benefits-container {
//   height: 750px;
//   position: relative;
// }

// .benefits-container-interno {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   z-index: 2;
// }

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.opacity-5 {
  opacity: 0.5;
}

.MuiStepper-root {
  padding: 0 !important;
}

.navbar-nav a,
.link-scroll-womve {
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.navbar-brand img {
  height: 50px;
  width: 150px;
}

.navbar-brand-stinky img {
  height: 35px;
  width: 120px;
}

.scroll-stinky {
  background: #348c84 !important;
}

@import 'node_modules/bootstrap/scss/bootstrap';

.MuiTimelineItem-missingOppositeContent:before {
  flex: none !important;
  padding: 0 !important;
}

//Se sobreescribe para que este por encima de todo sweet alert2
.swal2-container {
  z-index: 100000 !important;
}

//Se sobre escribe react-select
.react-select__menu,
.react-select__menu-list,
.react-select-container {
  background: white !important;
  z-index: 2;
}

.bg-womve {
  background: #348c84;
  border: 0 !important;
}

.navbar-brand {
  color: white;
  margin-left: 1rem;
}

.right-menu {
  display: flex;
  justify-content: flex-end;
}

.navbar-toggler-icon {
  color: white;
}

.navbar-nav a {
  color: white;
  text-decoration: none;
  padding: 0 1rem;
}

/* HomeLayout */
.homeToolbar {
  display: flex;
  justify-content: space-between;
}

.sidebarTitle {
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1976d2;
  color: white;
}

.container {
  background: white !important;
}

.app__1zhNH .closeButton__aMI1D {
  background: #004b56 !important;
}

/*Timeline order*/
:focus {
  outline: 0px solid transparent !important;
}
.timeline {
  padding: 0 5px 0 0;
  position: relative;
}
.timeline-nodes {
  padding-bottom: 5px;
  position: relative;
}
.timeline-nodes:nth-child(even) {
  flex-direction: row-reverse;
}
.timeline h3,
.timeline p {
  padding: 5px 5px;
}
.timeline h3 {
  font-weight: lighter;
  background: #004b56;
}
.timeline p,
.timeline time {
  color: #004b56;
}
.timeline::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  border-left: 3px solid #004b56;
  height: 100%;
  z-index: 1;
  transform: translateX(-50%);
}
.timeline-content {
  position: relative;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.timeline-nodes:nth-child(odd) h3,
.timeline-nodes:nth-child(odd) p {
  text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-date {
  text-align: left;
}

.timeline-nodes:nth-child(even) .timeline-date {
  text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
  content: '';
  position: absolute;
  top: 5%;
  left: 100%;
  width: 0;
  border-left: 10px solid #004b56;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.timeline-nodes:nth-child(even) .timeline-content::after {
  content: '';
  position: absolute;
  top: 5%;
  right: 100%;
  width: 0;
  border-right: 10px solid #004b56;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.timeline-image {
  position: relative;
  z-index: 2;
}
// .timeline-image::before {
// 	content: '';
// 	width: 80px;
// 	height: 80px;
// 	border: 2px dashed #004b56;
// 	border-radius: 50%;
// 	display: block;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	background-color: #fff;
// 	z-index: 1;
// }
// .timeline-image img {
// 	position: relative;
// 	z-index: 100;
// }

/* Footer */
footer {
  font-style: italic;
}

body {
  min-height: 100vh;
  position: relative;
  margin: 0;
  padding-bottom: 360px; //height of the footer
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

//Colors satatuses orders
.wait {
  color: #004b56;
  z-index: 2;
  font-size: 50px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-left: 12px;
  background: #fafafa;
}

//Table thead backgroun color
.MuiTableHead-root {
  background: rgb(233, 233, 233);
}

.headerTable {
  align-items: center;
}

//Card store welcome
.card-store {
  width: 30%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card-store-image {
  height: 200px;
}

//Card benefit
.card-benefit {
  width: 25%;
  //height: 200px;
  background: #fdfdfd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  justify-content: center;
}

//Card pricing
.card-pricing {
  width: 20%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media (max-width: 1360px) {
  .card-store-image {
    height: 150px;
  }
}

/*small device style*/
@media (max-width: 767px) {
  .navbar {
    padding: 8px 0;
  }
  .navbar-brand img {
    height: 35px;
    width: 120px;
  }

  .margin-top-responsive {
    margin-top: 16px;
  }

  .background-particles {
    display: none;
  }

  .benefits-container {
    height: auto !important;
  }

  .benefits-container-interno {
    position: static;
  }

  //Card store welcome
  .card-store,
  .card-benefit,
  .card-pricing {
    width: 100%;
  }

  .card-store-image {
    height: 150px;
  }

  .timeline-nodes:nth-child(odd) h3,
  .timeline-nodes:nth-child(odd) p {
    text-align: left;
  }
  .timeline-nodes:nth-child(even) {
    flex-direction: row;
  }
  // .timeline::before {
  // 	content: '';
  // 	display: block;
  // 	position: absolute;
  // 	top: 0;
  // 	right: 4%;
  // 	width: 0;
  // 	border-left: 3px solid #004b56;
  // 	height: 100%;
  // 	z-index: 1;
  // 	transform: translateX(50%);
  // }
  .timeline h3 {
    font-size: 1.7rem;
  }
  .timeline p {
    font-size: 14px;
  }
  .timeline-image {
    position: absolute;
    left: 0%;
    top: 60px;
    /*transform: translateX(-50%;);*/
  }
  .timeline-nodes:nth-child(odd) .timeline-content::after {
    content: '';
    position: absolute;
    top: 5%;
    left: auto;
    right: 100%;
    width: 0;
    border-left: 0;
    border-right: 10px solid #004b56;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  .timeline-nodes:nth-child(even) .timeline-content::after {
    content: '';
    position: absolute;
    top: 5%;
    right: 100%;
    width: 0;
    border-right: 10px solid #004b56;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  .timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
  }
  .timeline-image::before {
    width: 50px;
    height: 50px;
  }
}

/*extra small device style */
@media (max-width: 575px) {
  .timeline::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
  }
  .timeline-image {
    position: absolute;
    left: -15px;
    top: 35px;
  }
  .timeline-image::before {
    width: 20px;
    height: 20px;
  }

  .timeline-nodes {
    left: 8px;
  }

  .wait {
    font-size: 25px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-left: 8px;
  }
}
