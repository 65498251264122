.inputfile {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  height: 100px;
  width: 100px;
  border: 1px solid #ccc;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #ccc;
  i {
    font-size: 30px;
  }
}

.inputfile:focus + label,
.inputfile + label:hover {
  color: green;
  border: 1px solid green;
  cursor: pointer;
}
