.product-categories-search-container {
  background: blue;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 55px;
  right: 0px;
}
.search-store {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  font-size: 16px;
  padding: 5px;
  padding-left: 10px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  -webkit-transition: display 0.4s ease-in-out;
  transition: display 0.4s ease-in-out;
}

.product-categories-search-icon {
  background: white !important;
  position: absolute !important;
  bottom: 0;
  display: flex !important;
  top: 0;
  padding: 0 10px !important;
}

.search-store-visible {
  display: block;
}
.search-store-invisible {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-instagram {
  color: #3f729b;
}

.hover-facebook {
  color: #3b5998;
}

.hover-twitter {
  color: #00acee;
}

.hover-linkedin {
  color: #0e76a8;
}

.hover-youtube {
  color: #c4302b;
}

.public-store-header {
  height: 350px;
  background: rgb(224, 214, 214);
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.store-info {
  background: white;
  border-bottom: 1px solid rgb(233, 233, 233) !important;
  padding: 0.5rem 1rem;
}

.store-info__status {
  padding: 0.05rem 0.4rem;
}

.public-store-item-logo {
  height: 70px;
  width: 70px;
  min-height: 70px;
  min-width: 70px;
  border-radius: 100%;
  background-size: 100% 100%;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid rgb(233, 233, 233);
}

.store-info__social-networks {
  position: absolute;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.801);
  bottom: 0;
  right: 0;
  padding: 0.1rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 0;
}

.store-info__social-networks a {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.3rem !important;
}

.sections-categories {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #f1f1f1;
}

//Public store sections
.public-store-sections ul {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(221, 221, 221);
  margin: 0 50px;
  padding: 0;
}

#product-categories-container li:first-child {
  margin-left: 50px;
}

//Public store categories
.public-store-categories ul {
  height: 45px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding: 0;
  background: white;
}

.public-store-list-items__category-name {
  padding: 1rem 0 0 1rem;
}

.public-store-categories ul li:not(:first-child) {
  display: inline-block;
  margin-left: 2rem;
  cursor: pointer;
  padding: 5px;
}

//Public store card product item
.public-product-container {
  position: relative;
  margin: 0.5rem;
  width: 23%;
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.public-product-container img {
  width: 130px;
  height: 130px;
  cursor: pointer;
}

.public-product-content {
  padding: 0.3rem;
  width: 100%;
  position: relative;
}

.public-product-content h4 {
  font-size: 1rem;
  cursor: pointer;
}

.public-product-description {
  padding-top: 5px;
  display: flex;
  height: 45px;
  width: 100%;
  overflow: hidden;
  text-align: justify;
  line-height: 1rem;
  color: rgb(90, 90, 90);
  cursor: pointer;
}

.public-product-footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.public-store-product-price {
  color: #fa6838;
  padding-left: 5px;
}

.public-store-btn-add {
  font-weight: bold;
}
.public-store-btn-add i {
  margin: 0;
  padding: 0;
}

.columna {
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0; /* do not shrink - initial value: 1 */
  flex-basis: 140px; /* width/height  - initial value: auto */
}

.public-product-image {
  height: 130px;
  width: 100%;
}

//PUBLIC DETAIL STYLES
.public-detail-main-image {
  width: 400px;
}

@media (max-width: 1440px) {
  .public-product-container {
    width: 30%;
  }

  .public-product-content h4 {
    font-size: 1.3rem;
  }

  .public-product-content p {
    font-size: 0.9rem;
  }

  .public-store-card-product p {
    font-size: 16px;
  }

  .public-store-container-item-price {
    width: 100%;
  }

  .store-info h1 {
    font-size: 22px;
  }

  .public-store-header {
    height: 150px;
    background-size: cover;
  }
}

@media (max-width: 1024px) {
  .public-product-container {
    width: 46%;
  }
}

@media (max-width: 848px) {
  .public-product-container {
    width: 100%;
  }

  .store-info h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .public-product-container img {
    width: 110px;
    height: 110px;
    cursor: pointer;
  }

  .public-product-image {
    height: 150px;
  }

  .scroll-horizontal {
    white-space: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
  }

  /* [5] */
  .scroll-horizontal::-webkit-scrollbar {
    display: none;
  }
}
