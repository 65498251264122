.headerTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

.headerTable .title {
  font-size: 1.5rem;
}
